import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaPlus } from "react-icons/fa"; 
import bg3 from "../assets/bg3.jpg";
import bg2 from "../assets/bg2.jpg";
import shed from "../assets/shed.jpg";
import fence from "../assets/img1.jpg";
import electric from "../assets/img8.jpg";
import facia from "../assets/facia.jpg";
import landscape from "../assets/img2.jpg";
import cad from "../assets/cad.jfif";
import flooring from "../assets/flooring.jpg";
import exterior from "../assets/exterior.jpg";

const Services = () => {
  const images = [bg2, bg3];
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="text-center" style={{ fontFamily: "DM Sans" }}>
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#120309] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#dc0e0e] text-4xl font-bold mb-4">
            Our Services
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+14099324028"
              className="bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (409) 932-4028 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="max-w-4xl mx-auto text-dc0e0e px-4 pt-4 pb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4 text-white">
          <ServiceCard title="Sheds" backgroundImage={shed} link="/services/sheds"/>
          <ServiceCard title="Fences" backgroundImage={fence} />
          <ServiceCard title="Decks" backgroundImage={bg2} link="/services/decks"/>
          <ServiceCard title="Electrical" backgroundImage={electric} />
          <ServiceCard title="Home Exterior Repairs" backgroundImage={exterior} link="/services/home-exterior"/>
          <ServiceCard title="Exterior Work" backgroundImage={bg3} />
          <ServiceCard title="Home Interior Work" backgroundImage={flooring} link="/services/home-interior"/>
          <ServiceCard title="Landscape Construction" backgroundImage={landscape} />
          <ServiceCard title="CAD Design & Planning" backgroundImage={cad} link="/services/cad"/>

        </div>
            <h2 className="text-3xl font-semibold mt-8 mb-4 underline underline-offset-2">More Details:</h2>
          <Accordion title="Electrical" details={electrical} bgImage={electric}/>
          <Accordion title="Decks" details={decks} bgImage={bg2}/>
          <Accordion title="Landscape Construction" details={landscapeConstruction} bgImage={landscape}/>
          <Accordion title="Home Exterior Repairs" details={["Siding", "Facia", "Soffit, Vinyl Windows/Doors"]} bgImage={facia}/>
          <Accordion title="Home Interior Work" details={["Solid Hardwood Flooring", "Installations", "Crown Moulding"]} bgImage={exterior}/>
      </div>
    </div>
  );
};


const ServiceCard = ({ title, backgroundImage, link }) => {
  const cardStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <Link to={link}>
      <motion.div
        className="bg-335e88 bg-opacity-25 rounded-md p-4 flex flex-col items-center justify-center text-center hover:bg-opacity-50 transition duration-300 h-40 w-full"
        style={cardStyle}
        whileHover={{ scale: 1.05 }}
      >
        <h2 className="text-3xl font-semibold mb-2">{title}</h2>
      </motion.div>
    </Link>
  );
};


const Accordion = ({ title, details, bgImage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const accordionStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <motion.div className="mt-4 text-white rounded-md" style={accordionStyle}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between items-center font-semibold text-lg text-white"
      >
        <div className="pl-4 pt-2 py-1">{title}</div> <FaPlus className="text-white mr-2" />
      </button>
      {isOpen && (
        <motion.div
          className="mt-2 bg-[#dc0e0e] p-4 rounded-md"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <h4 className="text-lg font-bold mb-2 text-[#F2F4F7]">We offer:</h4>
          <ul className="list-disc list-inside text-[#F2F4F7]">
            {details.map((item, index) => (
              <li key={index} className="py-1">
                {item}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </motion.div>
  );
};

const landscapeConstruction = [
  "Trellises",
  "Pergolas",
  "Garden Arbors",
  "Gazebos",
];

const electrical = [
  "GFCI/ Arc Fault Troubleshooting",
  "Light Fixtures- Interior & Exterior",
  "In Ground Conduit Systems for Landscape Lighting",
];

const decks = [
  "New Construction/Repair Work",
  "Composite Decking, Ipe, Cedar",
  "Stairs and Railings",
];


export default Services;
